import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Button } from 'react-bootstrap';

const HSRDatabaseAlienspacePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Alien Space</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_manga.png"
            alt="Database"
          />
        </div>
        <div className="page-details">
          <h1>Alien Space</h1>
          <h2>A manga that acts as a prequel to Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297016"></div>
      <div className="page-content">
        <SectionHeader title="Alien Space" />
        <StaticImage
          src="../../../images/starrail/generic/alienspace_cover.webp"
          alt="Alien Space"
        />
        <p>
          Alien Space is an official manga created by miHoYo that expands the
          story from the Honkai Impact 3 game and also acts as a prequel to
          Honkai: Star Rail.
        </p>
        <h5>Official summary</h5>
        <p>
          The story is of 2029 where gates opened somewhere in the galaxy. The
          Sky People were ready to find the Beacon. But this story actually
          started in 2005, when after the 4th Divine Key’s repairs were
          finished.
        </p>
        <h5>Characters</h5>
        <p>
          Himeko Murata, Otto Apocalypse, Amber, Welt Yang, Ryusuke Murata,
          Frederica Nikola Tesla, Lieserl Albert Einstein and Ryoma Raiden.
        </p>
        <h5>Chapters</h5>
        <ul>
          <li>Prologue</li>
          <li>Extraterrestrial</li>
          <li>Misdirection</li>
          <li>Unlocking a Key</li>
          <li>Odd Presence</li>
          <li>The Attack</li>
          <li>Machina</li>
          <li>Standoff</li>
          <li>Family</li>
          <li>Choices</li>
          <li>The Truth</li>
          <li>The Savior</li>
          <li>Determination</li>
          <li>Secret Weapon</li>
          <li>Alien Key</li>
          <li>Singularity</li>
          <li>Finale New Journey</li>
        </ul>
        <h5>Where to read</h5>
        <p>You can read the Alien Space manga in the link below:</p>
        <Button
          variant="primary"
          href="https://manga.honkaiimpact3.com/book/1021"
          target="_blank"
          rel="noreferrer"
        >
          Read Alien Space
        </Button>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
    </DashboardLayout>
  );
};

export default HSRDatabaseAlienspacePage;

export const Head: React.FC = () => (
  <Seo
    title="Alien Space | Honkai: Star Rail | Prydwen Institute"
    description="A manga that acts as a prequel to Honkai: Star Rail."
  />
);
